export const DOWNLOAD_THE_APP_EVENT_NAME = "download_the_app_clicked";
export const COVID_MODAL_OPENED_EVENT_NAME =
  "covid-19-learn-more-modal-clicked";
export const COVID_LINK_CLICK_EVENT_NAME = "covid-19-learn-more-link-clicked";
export const COVID_BANNER_DISMISSED_EVENT_NAME = "covid-19-banner-dismissed";

export const RESCUE_BANNER_DISMISS_CLICK_EVENT_NAME =
  "rescue_banner_dismiss_clicked";
export const RESCUE_BANNER_CLICK_EVENT_NAME = "rescue_banner_clicked";
export const RESCUE_RECOMMENDATION_BOOK_EVENT =
  "rescue_recommendation_book_job_clicked";
export const RESCUE_RECOMMENDATION_FIND_ANOTHER_TASKER_EVENT =
  "rescue_recommendation_find_another_tasker_clicked";

export const RESCUE_BANNER_VIEWED = "rescue_banner_viewed";
export const RESCUE_MY_TASKS_VIEWED = "rescue_my_tasks_viewed";

export const TASKERPREVIEW_HIREREPLACEMENT_TAPPED =
  "taskerPreview_hireReplacement_tapped";
export const TASKERPREVIEW_FINDREPLACEMENT_TAPPED =
  "taskerPreview_findReplacement_tapped";

export const metricsEventConfig = {
  taskerPreview_seeProfile_tapped: {
    eventName: "taskerPreview_seeProfile_tapped",
    sources: ["bus"],
  },
  funnel_created: {
    eventName: "funnel_created",
    sources: ["bus"],
  },
  taskerPreview_hireReplacement_tapped: {
    eventName: "taskerPreview_hireReplacement_tapped",
    sources: ["bus"],
  },
  taskerPreview_findReplacement_tapped: {
    eventName: "taskerPreview_findReplacement_tapped",
    sources: ["bus"],
  },

  rescue_banner_dismiss_clicked: {
    eventName: "rescue_banner_dismiss_clicked",
    sources: ["bus"],
  },
  rescue_banner_clicked: {
    eventName: "rescue_banner_clicked",
    sources: ["bus"],
  },
  rescue_recommendation_book_job_clicked: {
    eventName: "rescue_recommendation_book_job_clicked",
    sources: ["bus"],
  },
  rescue_recommendation_find_another_tasker_clicked: {
    eventName: "rescue_recommendation_find_another_tasker_clicked",
    sources: ["bus"],
  },
  rescue_banner_viewed: {
    eventName: "rescue_banner_viewed",
    sources: ["bus"],
  },
  rescue_my_tasks_viewed: {
    eventName: "rescue_my_tasks_viewed",
    sources: ["bus"],
  },
  on_demand_referral_cta_visible: {
    eventName: "On Demand Referral CTA Visible",
    sources: ["bus"],
  },
  visitor_exit: {
    eventName: "visitor_exit",
    sources: ["bus"],
  },
  on_demand_advocate_copied_referral_link: {
    eventName: "On Demand Referral Advocate Copied Link to Clipboard",
    sources: ["bus"],
  },
  on_demand_referral_screen_viewed: {
    eventName: "On Demand Referral Screen Viewed",
    sources: ["bus"],
  },
  on_demand_referral_screen_dismissed: {
    eventName: "On Demand Referral Screen Dismissed",
    sources: ["bus"],
  },
  on_demand_referral_sent: {
    eventName: "On Demand Referral Sent",
    sources: ["bus"],
  },
  on_demand_referral_modal_redirect: {
    eventName: "On Demand Referral Modal Redirect",
    sources: ["bus"],
  },
  signup_intent_chosen: {
    eventName: "Signup_Intent_Chosen",
    sources: ["bus"],
  },
  signup_viewed: {
    eventName: "signup_viewed",
    sources: ["bus"],
  },
  signup_error: {
    eventName: "signup_error",
    sources: ["bus"],
  },
  login_viewed: {
    eventName: "login_viewed",
    sources: ["bus"],
  },
  login_success: {
    eventName: "login_success",
    sources: ["bus"],
  },
  login_error: {
    eventName: "login_error",
    sources: ["bus"],
  },
  login_signup_viewed: {
    eventName: "Login Signup Viewed",
    sources: ["bus", "gtm"],
  },
  [DOWNLOAD_THE_APP_EVENT_NAME]: {
    eventName: "Download_the_app_clicked",
    sources: ["bus"],
  },

  // chat notification popup
  notification_popup_request: {
    eventName: "notification_popup_request",
    sources: ["bus"],
  },
  notification_popup_allowed: {
    eventName: "notification_popup_allowed",
    sources: ["bus"],
  },
  notification_popup_blocked: {
    eventName: "notification_popup_blocked",
    sources: ["bus"],
  },
  notification_popup_clicked: {
    eventName: "notification_popup_clicked",
    sources: ["bus"],
  },

  // Build Form Events

  // Default address

  booking_flow_address_field_clicked: {
    eventName: "booking_flow_address_field_clicked",
    sources: ["bus"],
  },

  booking_flow_default_address_set: {
    eventName: "booking_flow_default_address_set",
    sources: ["bus"],
  },

  booking_flow_default_address_exists: {
    eventName: "booking_flow_default_address_exists",
    sources: ["bus"],
  },

  account_address_tab: {
    eventName: "account_address_tab",
    sources: ["bus"],
  },

  account_address_edit: {
    eventName: "account_address_edit",
    sources: ["bus"],
  },

  account_address_new: {
    eventName: "account_address_new",
    sources: ["bus"],
  },

  // Form Builder subscriptions
  smart_search_view_all: {
    eventName: "smart_search_view_all",
    sources: ["bus"],
  },

  posting_form_viewed: {
    eventName: "Form Viewed",
    sources: ["bus", "gtm"],
  },
  posting_form_completed: {
    eventName: "Form Completed",
    sources: ["bus", "gtm"],
  },
  ikea_select_items_viewed: {
    eventName: "IKEA Select Items Viewed",
    sources: ["bus", "gtm"],
  },
  ikea_search_by_name_viewed: {
    eventName: "IKEA Search By Name Viewed",
    sources: ["bus", "gtm"],
  },
  ikea_select_items_completed: {
    eventName: "IKEA Select Items Completed",
    sources: ["bus", "gtm"],
  },
  lead_nurture_intent_captured: {
    eventName: "Lead Nurture Intent Captured",
    sources: ["bus"],
  },
  ikea_job_see_taskers_and_prices_click: {
    eventName: "IKEA Select items redirect to reccos page.",
    sources: ["bus"],
  },
  // furniture items type events
  furniture_items_type_captured: {
    eventName: "Furniture Items Type Captured",
    sources: ["bus"],
  },

  // ikea redirect events
  ikea_redirect_choice: {
    eventName: "IKEA Redirect Choice",
    sources: ["bus"],
  },

  // quote form events
  quote_form_viewed: {
    eventName: "Quote Form Viewed",
    sources: ["bus"],
  },
  quote_form_completed: {
    eventName: "Quote Form Completed",
    sources: ["bus"],
  },

  // direct hire events
  direct_hire_form_viewed: {
    eventName: "Direct Hire Form Viewed",
    sources: ["bus"],
  },
  direct_hire_form_completed: {
    eventName: "Direct Hire Form Completed",
    sources: ["bus"],
  },

  // repost task book events
  repost_task_form_viewed: {
    eventName: "repost_task_form_viewed",
    sources: ["bus"],
  },

  // datetime pre-select modal events
  datetime_pre_select_modal_viewed: {
    eventName: "DateTime Pre-Select Modal Viewed",
    sources: ["bus"],
  },
  datetime_pre_select_modal_completed: {
    eventName: "DateTime Pre-Select Modal Completed",
    sources: ["bus"],
  },
  gm_suggested_tasker: {
    eventName: "GM Suggested Tasker",
    sources: ["bus"],
  },

  // recommendation events
  recommendations_viewed: {
    eventName: "Recommendations Viewed",
    sources: ["bus"],
  },
  recommendation_cta_clicked: {
    eventName: "Recommendations CTA Selected",
    sources: ["bus"],
  },
  recommendation_page_clicked: {
    eventName: "Recommendation Page Clicked",
    sources: ["bus"],
  },
  recommendation_tasker_schedule_cta_clicked: {
    eventName: "recommendation_tasker_schedule_cta_clicked",
    sources: ["bus"],
  },
  recommendation_tasker_profile_reviews_category_changed: {
    eventName: "Recommendations Tasker Profile Reviews Category Changed",
    sources: ["bus"],
  },
  recommendation_tasker_profile_reviews_page_changed: {
    eventName: "Recommendations Tasker Profile Reviews Page Changed",
    sources: ["bus"],
  },
  recommendation_tasker_profile_cta_clicked: {
    eventName: "Recommendations Tasker Profile CTA Selected",
    sources: ["bus"],
  },
  recommendation_review_modal_shown: {
    eventName: "See Reviews Selected",
    sources: ["bus"],
  },
  tasker_schedule_viewed: {
    eventName: "Tasker Schedule Viewed",
    sources: ["bus"],
  },
  recommendations_schedule_filter_changed: {
    eventName: "Recommendations Date Filter Changed",
    sources: ["bus"],
  },
  recommendations_time_filter_changed: {
    eventName: "Recommendations Time Filter Changed",
    sources: ["bus"],
  },
  recommendations_price_filter_changed: {
    eventName: "Recommendations Price Filter Changed",
    sources: ["bus"],
  },
  recommendations_vehicle_filter_changed: {
    eventName: "Recommendations Vehicle Filter Changed",
    sources: ["bus"],
  },
  recommendations_sort_changed: {
    eventName: "Recommendations Sort Changed",
    sources: ["bus"],
  },
  recommendations_recurring_filter_changed: {
    eventName: "Recommendations Recurring Filter Changed",
    sources: ["bus"],
  },
  recommendations_badge_filter_changed: {
    eventName: "Recommendations Badge Filter Changed",
    sources: ["bus"],
  },
  recommendations_job_schedule_changed: {
    eventName: "Recommendations Job Schedule Changed",
    sources: ["bus"],
  },
  tasker_detail_viewed: {
    eventName: "Tasker Detail Viewed",
    sources: ["bus"],
  },
  tasker_photo_section_displayed: {
    eventName: "Tasker Photo Section Displayed",
    sources: ["bus"],
  },
  tasker_photo_viewed: {
    eventName: "Tasker Photo Viewed",
    sources: ["bus"],
  },
  tasker_photo_reported: {
    eventName: "Tasker Photo Reported",
    sources: ["bus"],
  },

  // build flow signup
  // google subscriptions
  signup_success: [
    {
      eventName: "userCreated",
      sources: ["gtm", "googleConversion", "bus"],
    },
    {
      eventName: "signup_success",
      sources: ["bus"],
    },
  ],

  // confirmation page
  confirmation_page_viewed_1104: {
    eventName: "Confirmation Page Legacy",
    sources: ["bus"],
  },
  confirmation_page_viewed: {
    eventName: "Confirmation Viewed",
    sources: ["bus"],
  },
  confirmation_edit_task_clicked: {
    eventName: "Confirmation Edit Task Clicked",
    sources: ["bus"],
  },
  confirm_and_chat_button_clicked: {
    eventName: "Confirm and Chat Clicked",
    sources: ["bus"],
  },
  confirmation_signup_viewed: {
    eventName: "Confirmation Signup Viewed",
    sources: ["bus"],
  },
  confirmation_signup_error: {
    eventName: "Confirmation Signup Error ",
    sources: ["bus"],
  },
  confirmation_user_created: {
    eventName: "Confirmation User Created",
    sources: ["bus"],
  },
  confirmation_signup_success: {
    eventName: "Confirmation Signup Success", // If you change this name change it inside become_a_tasker.haml too
    sources: ["bus"],
  },
  confirmation_login_viewed: {
    eventName: "Confirmation Login Viewed",
    sources: ["bus"],
  },
  confirmation_login_error: {
    eventName: "Confirmation Login Error",
    sources: ["bus"],
  },
  confirmation_login_success: {
    eventName: "Confirmation Login Success",
    sources: ["bus"],
  },
  confirmation_interstitial_presented: {
    eventName: "Confirmation Interstitial Presented",
    sources: ["bus"],
  },
  confirm_details_secure_it: {
    eventName: "Confirm Details Secure It",
    sources: ["bus"],
  },
  confirm_time_selected: {
    eventName: "Confirm Time Selected",
    sources: ["bus"],
  },
  job_posted: [
    {
      eventName: "invitationSent",
      sources: ["bus", "gtm"],
    },
  ],
  job_posted_1107: {
    eventName: "Invitation Sent 1107",
    sources: ["bus"],
  },

  // Dashboard Events

  cancel_repost_asked: {
    eventName: "Cancellation Ask Repost Shown",
    sources: ["bus"],
  },
  cancel_repost_declined: {
    eventName: "Cancellation Ask Repost Declined",
    sources: ["bus"],
  },
  cancel_repost_task_reposted: {
    eventName: "Cancellation Ask Repost Hire Clicked",
    sources: ["bus"],
  },
  dashboard_viewed: {
    eventName: "Dashboard_Viewed",
    sources: ["bus"],
  },
  download_app_modal_displayed: {
    eventName: "App Download Modal displayed",
    sources: ["bus"],
  },
  download_app_modal_txt_msg_asked: {
    eventName: "App Download Modal CTA clicked",
    sources: ["bus"],
  },
  // repost_task subscriptions
  repost_tasker_clicked: {
    eventName: "repost_tasker_clicked",
    sources: ["bus"],
  },
  cancel_task_attempted: {
    eventName: "Cancel Task Attempted",
    sources: ["bus"],
  },
  reschedule_chat_with_tasker_clicked: {
    eventName: "Reschedule Chat With Tasker",
    sources: ["bus"],
  },
  reschedule_cancel_task_clicked: {
    eventName: "Reschedule Cancel Task Clicked",
    sources: ["bus"],
  },

  // Feedback Metrics

  poster_toggled_tip_amount: {
    eventName: "poster_toggled_tip_amount",
    sources: ["bus"],
  },
  poster_left_tip_for_tasker: {
    eventName: "poster_left_tip_for_tasker",
    sources: ["bus"],
  },
  poster_viewed_tipping_form: {
    eventName: "poster_viewed_tipping_form",
    sources: ["bus"],
  },

  // Tasker Profile Metrics

  profile_build_cta_clicked: {
    eventName: "Profile CTA Clicked",
    sources: ["bus"],
  },
  profile_hire_cta_clicked: {
    eventName: "Profile Hire CTA Clicked",
    sources: ["bus"],
  },
  profile_reviews_category_changed: {
    eventName: "Profile Reviews Category Changed",
    sources: ["bus"],
  },
  profile_reviews_page_changed: {
    eventName: "Profile Reviews Page Changed",
    sources: ["bus"],
  },

  // IKEA Metrics

  // GTM metrics
  ikea_book_now_clicked: {
    eventName: "IKEA Book Now Clicked",
    sources: ["gtm"],
  },

  ikea_cart_page_viewed: {
    eventName: "IKEA Cart Page Viewed",
    sources: ["gtm"],
  },

  ikea_cart_checkout_clicked: {
    eventName: "IKEA Cart Checkout Clicked",
    sources: ["gtm"],
  },

  ikea_booking_confirm_clicked: {
    eventName: "IKEA Booking Confirmed",
    sources: ["gtm"],
  },

  ikea_faq_question_opened: {
    eventName: "IKEA FAQ Question Opened",
    sources: ["bus"],
  },
  ikea_payment_method_viewed: { //TODO: Remove after the SPE experiment is over (MOBILE-1031)
    eventName: "Activate SPE PayPal IKEA EU Experiment",
    sources: ["bus"],
  },
  // BUS Metrics
  ikea_flow_return_link_clicked: {
    eventName: "IKEA Flow Return Clicked during Fast Pivot A/B",
    sources: ["bus"],
  },

  // landing page viewed
  ikea_landing_page_viewed: {
    eventName: "IKEA Landing Page Viewed",
    sources: ["bus", "gtm"],
  },
  ikea_address_modal_viewed: {
    eventName: "IKEA Address Modal",
    sources: ["bus", "gtm"],
  },
  ikea_landing_page_other_services_clicked: {
    eventName: "See Other Services Clicked",
    sources: ["bus", "gtm"],
  },
  ikea_landing_page_specific_service_clicked: {
    eventName: "Specific Service Clicked",
    sources: ["bus", "gtm"],
  },
  ikea_landing_page_zip_in_completed: {
    eventName: "IKEA Landing Page Zip-In Completed",
    sources: ["bus", "gtm"],
  },
  ikea_partner_element_option_clicked: {
    eventName: "IKEA Partner Element Option Clicked",
    sources: ["bus", "gtm"],
  },

  // price parity flow
  ikea_order_by_item_viewed: {
    eventName: "IKEA Search Viewed",
    sources: ["bus", "gtm"],
  },

  // quote flow
  simple_quote_form_viewed: {
    eventName: "Simple Quote Form Viewed",
    sources: ["bus", "gtm"],
  },
  simple_quote_confirm_viewed: {
    eventName: "Simple Quote Confirm Viewed",
    sources: ["bus", "gtm"],
  },
  simple_quote_job_draft_posted: {
    eventName: "Simple Quote Draft Posted",
    sources: ["bus", "gtm"],
  },

  // update cart
  ikea_cart_updated: {
    eventName: "IKEA Cart Updated",
    sources: ["bus"],
  },

  // at-home simplified flow
  simple_hire_form_viewed: {
    eventName: "Simple Hire Form Viewed",
    sources: ["bus", "gtm"],
  },
  simple_hire_confirm_viewed: {
    eventName: "Simple Hire Confirm Viewed",
    sources: ["bus", "gtm"],
  },
  simple_hire_job_posted: {
    eventName: "Simple Hire Job Posted",
    sources: ["bus", "gtm"],
  },

  // Friend (Referral) events

  referral_page_viewed: {
    eventName: "Referral_Page_Viewed",
    sources: ["bus"],
  },

  // Marketing Group events

  marketing_group_viewed: {
    eventName: "Marketing_Group_Viewed",
    sources: ["bus"],
  },
  marketing_group_index_viewed: {
    eventName: "Marketing_Group_Index_Viewed",
    sources: ["bus"],
  },

  choose_project_expand_clicked: {
    eventName: "Choose_Project_Expand_Clicked",
    sources: ["bus"],
  },
  choose_your_project_clicked: {
    eventName: "Choose_Your_Project_Clicked",
    sources: ["bus"],
  },

  // Home Page events

  homepage_viewed: {
    eventName: "Homepage_Viewed",
    sources: ["bus"],
  },
  search_bar_activated: {
    eventName: "Search_Bar_Activated",
    sources: ["bus"],
  },
  header_navigation_clicked: {
    eventName: "Header_Navigation_Clicked",
    sources: ["bus"],
  },
  popular_projects_clicked: {
    eventName: "Popular_Projects_Clicked",
    sources: ["bus"],
  },
  popular_projects_expand_clicked: {
    eventName: "Popular_Projects_Expand_Clicked",
    sources: ["bus"],
  },
  review_clicked: {
    eventName: "Review_Clicked",
    sources: ["bus"],
  },
  post_link_clicked: {
    eventName: "Post_Link_Clicked",
    sources: ["bus"],
  },
  get_help_today_clicked: {
    eventName: "Get_Help_Today_Clicked",
    sources: ["bus"],
  },
  get_inspired_link_clicked: {
    eventName: "Get_Inspired_Link_Clicked",
    sources: ["bus"],
  },
  value_prop_link_clicked: {
    eventName: "Value_Prop_Link_Clicked",
    sources: ["bus"],
  },
  hero_search_button_clicked: {
    eventName: "Hero_Search_Button_Clicked",
    sources: ["bus"],
  },
  locations_link_clicked: {
    eventName: "Locations_Link_Clicked",
    sources: ["bus"],
  },
  locations_expand_clicked: {
    eventName: "Locations_Expand_Clicked",
    sources: ["bus"],
  },
  location_lookup_button_clicked: {
    eventName: "Location Lookup Button Clicked",
    sources: ["bus"],
  },
  geocode_failed: {
    eventName: "geocode_failed",
    sources: ["bus"],
  },
  become_tasker_clicked: {
    eventName: "Become_Tasker_Clicked",
    sources: ["bus"],
  },
  signup_clicked: {
    eventName: "Signup_Clicked",
    sources: ["bus"],
  },
  featured_taskers_navigation_clicked: {
    eventName: "Featured_Taskers_Navigation_Clicked",
    sources: ["bus"],
  },
  reviews_navigation_clicked: {
    eventName: "Reviews_Navigation_Clicked",
    sources: ["bus"],
  },

  // Task templates
  task_template_viewed: {
    eventName: "Task_Template_Viewed",
    sources: ["bus"],
  },
  task_template_service_page_viewed: {
    eventName: "Task_Template_Service_Page_Viewed",
    sources: ["bus"],
  },

  // Pre-populated Templates
  pre_populated_template_address_modal_viewed: {
    eventName: "Pre Populated Template Address Modal Viewed",
    sources: ["bus"],
  },

  pre_populated_template_address_modal_clicked: {
    eventName: "Pre Populated Template Address Modal Clicked",
    sources: ["bus", "gtm"],
  },

  // Geo marketing pages
  locations_index_viewed: {
    eventName: "Locations_Index_Viewed",
    sources: ["bus"],
  },
  location_page_viewed: {
    eventName: "Location_Page_Viewed",
    sources: ["bus"],
  },
  location_service_page_viewed: {
    eventName: "Location_Service_Page_Viewed",
    sources: ["bus"],
  },
  location_service_cta_hero_book_now: {
    eventName: "Location_Service_CTA_Hero_BookNow",
    sources: ["bus"],
  },
  location_service_cta_taskers_nearby_book_now: {
    eventName: "Location_Service_CTA_TaskersNearby_BookNow",
    sources: ["bus"],
  },
  location_service_carousel_arrow_see_more_cards: {
    eventName: "Location_Service_CarouselArrow_SeeMoreCards",
    sources: ["bus"],
  },
  location_service_cta_testimonials_book_now: {
    eventName: "Location_Service_CTA_Testimonials_BookNow",
    sources: ["bus"],
  },

  // Become a tasker page
  tasker_signup_viewed: {
    eventName: "Tasker_Signup_Viewed",
    sources: ["bus"],
  },
  tasker_ios_clicked: {
    eventName: "Tasker_Ios_Clicked",
    sources: ["bus"],
  },
  tasker_android_clicked: {
    eventName: "Tasker_Android_Clicked",
    sources: ["bus"],
  },
  tasker_signup_success: {
    eventName: "Tasker Signup Success", // If you change this name change it inside become_a_tasker.haml too
    sources: ["bus"],
  },
  tasker_metro_selected: {
    eventName: "Tasker_Metro_Selected",
    sources: ["bus"],
  },
  tasker_category_selected: {
    eventName: "Tasker_Category_Selected",
    sources: ["bus"],
  },
  tasker_get_started_clicked: {
    eventName: "Tasker_Get_Started_Clicked",
    sources: ["bus"],
  },
  tasker_signup_scroll: {
    eventName: "Tasker_Signup_Scroll",
    sources: ["bus"],
  },

  // subscribe to global metric events.
  page_referred: {
    eventName: "page_referred",
    sources: ["bus"],
  },

  pageview: {
    eventName: "pageview",
    sources: ["ga"],
  },

  recommendations_tasker_profile_clicked: {
    eventName: "recommendations_tasker_profile_clicked",
    sources: ["bus"],
  },

  tasker_profile_continue_purchase_flow_clicked: {
    eventName: "tasker_profile_continue_purchase_flow_clicked",
    sources: ["bus"],
  },
  confirmation_page_continue_and_chat_clicked: {
    eventName: "confirmation_page_continue_and_chat_clicked",
    sources: ["bus"],
  },
  job_booked_auto_match_failed: {
    eventName: "job_booked_auto_match_failed",
    sources: ["bus"],
  },
  job_booked_auto_match_sucess: {
    eventName: "job_booked_auto_match_success",
    sources: ["bus"],
  },
  task_address_changed: {
    eventName: "task_address_changed",
    sources: ["bus"],
  },
  tasker_unavailable_in_the_new_address: {
    eventName: "tasker_unavailable_in_the_new_address",
  },
  task_details_saved: {
    eventName: "task_details_saved",
    sources: ["bus"],
  },
};
