/* eslint-disable camelcase */
export const CLEANING_CATEGORIES = [59, 400];
export const DELIVERY_CATEGORIES = [1, 5, 403];
export const FURNITURE_ASSEMBLY_CATEGORY = 33;
export const GOODWILL_DONATION_TEMPLATE_ID = 3502;
/*
TODO: get ikea categories / task template from API
*/

const windowGlobal = typeof window === "undefined" ? {} : window;
const { partnerServices } = windowGlobal;
const {
  badges,
  category_id,
  current_supported_locales,
  managed_user_id,
  products,
  stores,
  task_template_id,
  title,
  minimum_price,
  currency_code,
  waiver_required,
  allow_quote_for_disabled_metro,
  cancellation_policy_info_url,
  ptnr_display_intended_use_cta,
  covid_19_support_info_url,
  ikea_partner_services,
  ikea_double_post_message,
  admin_products,
  phase_manager,
  secure_it_waiver_required,
  tipping_disabled_for_locale,
} = windowGlobal.IKEA_ATTRIBUTES || {};

export const IS_PHASE_0_1104 = phase_manager?.is_phase_0_1104 || false;
export const IS_PHASE_ARTICLE_PRICING =
  phase_manager?.is_phase_article_pricing || false;
export const IS_PREPAID_TESTING = phase_manager?.is_prepaid_testing || false;
export const IS_PHASE_2 = phase_manager?.phase_2 || false;

export const SECURE_IT_WAIVER_REQUIRED = secure_it_waiver_required || false;
export const TIPPING_DISABLED_FOR_LOCALE = tipping_disabled_for_locale || false;

export const IKEA_LEGACY_CATEGORY = 1104;
export const IKEA_GM_CATEGORY = 1107;
export const BOOTSTRAP_URL = "/api/v3/web-client/bootstrap.json";
export const PAYMENT_INTENTS_URL = "/api/v3/payments/intents";
export const CREATE_PAYMENT_INTENTS_URL = "/api/v3/payments/create-intent";
export const CREATE_STRIPE_PAYMENT_METHOD_URL =
  "/api/v3.1/payment_methods.json";
export const IKEA_TASK_TITLE = title;
export const IKEA_BADGES = badges;
export const IKEA_PRODUCTS = products;
export const IKEA_ADMIN_PRODUCTS = admin_products;
export const CURRENT_SUPPORTED_LOCALES = current_supported_locales || [];
export const IKEA_CATEGORIES = [category_id];
export const IKEA_TASK_TEMPLATE = task_template_id;
export const IKEA_RABBIT_ID = managed_user_id;
export const IKEA_MINIMUM_PRICE = minimum_price;
export const CURRENCY_CODE = currency_code;
export const IKEA_STORE_COOKIE_NAME = "tr-ikea-store-name";
export const IKEA_DATA_COOKIE_NAME = "tr-ikea-data";
export const ALERTS_STORAGE_KEY = "tr-v3-notifications";
export const FLASH_STORAGE_KEY = "tr-v3-flash-notifications";
export const LIVE_BUILD_PATH_PREFIX = "/tasks/r/";
export const CREDIT_CARD_FORM_FIELDS = [
  "card_number",
  "cvv",
  "expiration_month",
  "expiration_year",
  "postal_code",
];
export const CA_MOVING_RESTRICTED_METROS = [1053, 1059, 1068, 1142, 1072];
export const LA_MOVING_RESTRICTED_METROS = [1084];
export const MOVING_RESTRICTED_CATS = [6, 413, 522];
export const TABLET_QUERY = "(max-width: 800px)";
export const IKEA_LARGE_JOB_MIN_SECONDS = 60 * 60 * 5; // large jobs considered > 5hrs.
export const SESSION_COOKIE_NAME = "session";
export const DISMISSED_INTENDED_USE_BANNER_COOKIE_NAME =
  "dismissed-intended-use-banner";
export const IKEA_STORES = stores;
export const WAIVER_REQUIRED = waiver_required;
export const ALLOW_QUOTE_FOR_DISABLED_METRO = allow_quote_for_disabled_metro;
export const CANCELLATION_POLICY_INFO_URL = cancellation_policy_info_url;
export const COVID_19_SUPPORT_INFO_URL = covid_19_support_info_url;
export const PTNR_DISPLAY_INTENDED_USE_CTA = ptnr_display_intended_use_cta;
export const IKEA_PARTNER_SERVICES = ikea_partner_services;
export const IKEA_DOUBLE_POST_MESSAGE = ikea_double_post_message;
export const REFERRER_QUERY_PARAMS = [
  "utm_source",
  "utm_medium",
  "utm_term",
  "utm_content",
  "utm_campaign",
  "ref",
  "gclid",
];
export const POSITIVE_STAR_RATING = 3;

export const TIME_RANGE_OFFSET_SECONDS = {
  morning: {
    min_offset_seconds: 28800,
    max_offset_seconds: 43200,
  },
  afternoon: {
    min_offset_seconds: 43200,
    max_offset_seconds: 61200,
  },
  evening: {
    min_offset_seconds: 61200,
    max_offset_seconds: 77400,
  },
};

export const PARTNER_SERVICES = partnerServices;

export const TASKER_IOS_STORE_URL =
  "https://apps.apple.com/app/tasker-by-taskrabbit/id1455415833?ls=1";
export const TASKER_ANDROID_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.taskrabbit.droid.rabbit";
export const IKEA_CA_REDIRECT_URL =
  "https://iweof.sharepoint.com/:w:/t/o365g_taskrabbit_retca093/EZboKpaPCZZMtiPDuT-evPQBlfRrPvIV-0Y7z2tjX9s_ww?e=lcqiiz";

export const DEFAULT_VEHICLE_REQUIREMENT = "none";
export const CAR_VEHICLE_REQUIREMENT = "Car";
export const TRUCK_VEHICLE_REQUIREMENT = "Pickup Truck";

export const MAX_TASKERS_TO_DISPLAY = 100;
export const TASKERS_PER_PAGE = 15;

export const IKEA_MAX_QUANTITY = 30;
